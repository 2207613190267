<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      :show-options="true"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <payment-entry-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.creditorInvoiceId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <a @click.prevent="addNew(entity.vendorId, $event)"
                    :href="generateUrl(entity.vendorId)">{{ entity.invoiceRef }}</a>
                </td>
                <td>{{ entity.vendorName }}</td>
                <td>{{ $filters.formatDateTimezone(entity.invoiceDate, $userInfo.locale) }}</td>
                <td>{{ $filters.formatDateTimezone(entity.due, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.balance | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.totalAmount | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.gstAmount | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.amount | formatNumber($userInfo.locale) }}</td>
                <td class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    @click.prevent="addNew(entity.vendorId)"
                    :href="generateUrl(entity.vendorId)"
                    data-tooltip="Payment">
                    <span class="icon is-medium">
                      <i class="mdi mdi-currency-usd mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
    <vue-fab v-if="false"
      position="bottom-right"
      :z-index="30"
      :actions="fabActions"
      main-tooltip="Payment Entry Actions"
      @advancePayment="advancePayment" />
    <payment-entry-advance-payment-modal v-if="isAdvancePaymentModalActive"
      :active.sync="isAdvancePaymentModalActive"
      v-model="entitySummaries"
      @close="closeAdvancePaymentModal" />
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import { Columns } from './columns'
import StoreMixin from './storeMixin'
import BulmaTable from '@/components/BulmaTable'
import PaymentEntryService from './PaymentEntryService'
import PaymentEntryListSideMenu from './PaymentEntryListSideMenu'
import PaymentEntryAdvancePaymentModal from './PaymentEntryAdvancePaymentModal'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { AppHeaderButtonTypes } from '@/enums'
import { Emailer } from '@/classes'
import { VueFab } from '@/components/VueFab'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import _transform from 'lodash/transform'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'PaymentEntryListView',
  components: {
    AppDetailHeader,
    [PaymentEntryListSideMenu.name]: PaymentEntryListSideMenu,
    BulmaTable,
    VueFab,
    PaymentEntryAdvancePaymentModal
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, StoreMixin],
  data() {
    return {
      entitySummaries: [],
      totalRows: 0,
      filter: {
        invoiceNo: '',
        venName: '',
        quoteNo: '',
        filterType: 1,
        sortColumn: Columns[2].name,
        sortOrder: Columns[2].defaultOrder,
        pageIndex: 1,
        pageSize: 16
      },
      toggle: true,
      selectedRow: null,
      isTableLoading: false,
      isAdvancePaymentModalActive: false,
      fabActions: []
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  created() {
    this.setupFabButtons()
    this.getData()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    generateUrl(id) {
      return `paymententry/${id}`
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          // this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
        case AppHeaderButtonTypes.AdvancePaymentEntry:
          this.advancePayment()
          break
      }
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter.invoiceNo = ''
      this.filter.venName = ''
      this.filter.quoteNo = ''
      this.filter.filterType = 1
      this.filter.sortColumn = Columns[2].name
      this.filter.sortOrder = Columns[2].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 16
      this.persistFilter()
      this.getData()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    async getData() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await PaymentEntryService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    print() {
      let params = Object.assign(
        {
          companyId: this.$userInfo.companyId,
          timezoneoffset: this.$filters.getSiteTimezoneOffset()
        },
        this.filter
      )
      params.pageIndex = 1
      params.pageSize = this.totalRows
      // Convert properties to lower case
      params = _transform(params, function(result, value, key) {
        result[key.toLowerCase().replace('sortorder', 'sortdirection')] = value
      })

      // Create KeyValuePairs and add to vuex for later retrieval by PrintPreviewEx component
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Payment Entry Listing'
      emailer.reportName = 'PaymentEntrytList'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    convertToKeyValuePairs(parameters) {
      const keyValuePairs = Object.keys(parameters).map(function(key) {
        return new KeyValuePairModel(key, parameters[key])
      })
      return keyValuePairs
    },
    async addNew(vendorId) {
      this.addStoreItemPaymentEntry(vendorId)
    },
    async setupFabButtons() {
      this.fabActions.splice(this.fabActions.length, 1, {
        name: 'advancePayment',
        icon: 'currency-usd',
        color: '#a8a145',
        tooltip: 'Advance Payment Entry'
      })
    },
    async advancePayment() {
      await this.addStoreItem()
      this.isAdvancePaymentModalActive = true
    },
    closeAdvancePaymentModal() {
      this.isAdvancePaymentModalActive = false
    }
  }
}
</script>
